/*
 * File: Reprise.tsx
 * Project: autofi-front
 * File Created: Thursday, 16th March 2023 03:33:13
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 27th March 2023 06:26:43
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import PersonnalContact from "Content/Contact/PersonnalContact/PersonnalContact";
import { PersonnalContactType } from "Content/Contact/PersonnalContact/PersonnalContact.type";
import { FormEvent, ReactNode, useEffect, useState } from "react";
import RegNbInput from "Content/BuyBack/RegNbInput/RegNbInput";
import translator from "MultiLanguage/TranslationWrapper";
import AutobizService from "Services/Autobiz/AutobizService";
import { Form } from "react-router-dom";
import { AutobizCar } from "Utils/AutobizCar.type";
import { CarValuesInput } from "Content/BuyBack/CarValuesInput/CarValuesInput";
import { CarIntelInput } from "Content/BuyBack/CarValuesInput/CarValuesInput.type";
import PicturesContainer from "Content/BuyBack/PicturesContainer/PicturesContainer";
import styles from "Content/BuyBack/BuyBack.module.css";
import { AddressContact } from "Content/Contact/AddressContact/AddressContact";
import { Address } from "Content/Contact/AddressContact/AddressInterface";
import BuyBackService from "Services/BuyBack/BuyBackService";
import ProgressStepper from "Content/Filters/ButtonFilter/ProgressBar/ProgressBar";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import ModalConfirmation from "Content/Modals/ModalConfirmation/ModalConfirmation";

export function BuyBack() {
    const [curStep, setCurStep] = useState(0);
    const [personnalIntels, setPersonnalIntels] = useState<PersonnalContactType>({});
    const [carValuesIntels, setCarValuesIntels] = useState<CarIntelInput>({});
    const [regNb, setRegNb] = useState<string>("");
    const [carIntels, setCarIntels] = useState<AutobizCar>({});
    const [buttonEnable, setButtonEnable] = useState<ReactNode>(false);
    const [addedPic, setAddedPic] = useState<File[]>([]);
    const [wrongRegNb, setWrongRegNb] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isOkay, setIsOkay] = useState<boolean>(false);
    const [address, setAddress] = useState<Address>({
        houseNumber: "",
        street: "",
        postalCode: "",
        city: "",
        score: 0
    });
    const [spinner, setSpinner] = useState(false);
    const [comments, setComments] = useState("");
    const [sendEnable, setSendEnable] = useState<boolean>(false);

    const fieldsAreFilled = (e: FormEvent<HTMLFormElement>) => {
        let ret = true;
        if (personnalIntels.firstName === undefined || personnalIntels.firstName === "" ||
            personnalIntels.lastName === undefined || personnalIntels.lastName === "" ||
            personnalIntels.email === undefined || personnalIntels.email === "" ||
            personnalIntels.phone === undefined || personnalIntels.phone === "" ||
            address.postalCode === "" || carIntels.status === undefined ||
            carIntels.status === false || carValuesIntels.version === undefined || carValuesIntels.mileage === undefined) {
            e.preventDefault();
            ret = false;
        }
        return ret;
    };
    const handleToggleModal = (event: React.MouseEvent<HTMLElement>) => {
        setOpenModal(false);
    };
    const fieldsAreVerified = (e: FormEvent<HTMLFormElement>) => {
        let ret = true;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(personnalIntels.email!) ||
        !/^[\+]?[(]?[0-9]{2,3}[)]?([-\s\. ]?[0-9]{2,3}){4,6}$/.test(personnalIntels.phone!) ||
        !/^\d{5,6}$/.test(address.postalCode!) || carValuesIntels.mileage! <= 0) {
            e.preventDefault();
            ret = false;
        }
        return ret;
    };

    const verifyFields = () => {
        let ret = true;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(personnalIntels.email!) ||
        !/^[\+]?[(]?[0-9]{2,3}[)]?([-\s\. ]?[0-9]{2,3}){4,6}$/.test(personnalIntels.phone!) ||
        !/^\d{5,6}$/.test(address.postalCode!) || carValuesIntels.mileage! <= 0) {
            ret = false;
        }
        return ret;
    };

    const handleNewBuyBack = async (e: FormEvent<HTMLFormElement>) => {
        if (curStep < 3)
            return false;
        setSpinner(true)
        const myPersonnalIntels = personnalIntels;
        myPersonnalIntels.address = address;
        setPersonnalIntels(myPersonnalIntels);
        if (fieldsAreFilled(e) && fieldsAreVerified(e)) {
            carIntels.versions = [carValuesIntels.version!];
            await BuyBackService.addNewBuyBackRequest({carIntels: carIntels, mileage: carValuesIntels.mileage!.toString(), personnalIntel: myPersonnalIntels, pictures: addedPic, comments: {text: comments}})
            .then(res => {
                if (res != null && res.id != null && res.id != undefined)
                    setIsOkay(true);

                setOpenModal(true);
            });
        }
        setSpinner(false);
        return true;
    };

    const handleRegNbIntelRequest = async (e: any) => {
        e.preventDefault();
        setSpinner(true);
        setWrongRegNb(false);
        const carIntel = await AutobizService.getIntelFromReg(regNb);
        if (carIntel?.status) {
            if (carIntel?.versions != undefined) {
                setCarIntels(carIntel!);
                if (carIntel.versions.length > 0)
                setCurStep(curStep + 1);
                else
                setCurStep(curStep + 2);
            }
        }
        else {
            setWrongRegNb(true);
        }
        setSpinner(false);
        setButtonEnable(false);
    };

    const handleNextButton = async (e: any) => {
        e.preventDefault();
        setCurStep(curStep + 1);
        setButtonEnable(false);
    };

    const enableNext = (val?: boolean) => {
        val !== undefined ? setButtonEnable(val) : setButtonEnable(true);
    };

    const handleBackButton = (e: any) => {
        e.preventDefault();
        setCurStep(curStep - 1);
        if (curStep == 1)
            setButtonEnable(true);
    };

    const pictures = () => {
        return (
            <>
                <PicturesContainer pictures={addedPic} setPictures={setAddedPic} />
            </>
        );
    };

    const checkSendEnable = () => {
        return personnalIntels.email != "" && personnalIntels.phone != "" && personnalIntels.firstName != "" && personnalIntels.lastName != "" && address.postalCode != "";
    };

    useEffect(() => {
        if (buttonEnable && checkSendEnable())
            setSendEnable(true);
        else
            setSendEnable(false);
    }, [buttonEnable]);

    useEffect(()=>{
        enableNext(verifyFields());
    }, [address, personnalIntels]);

    return (
        <div className={`${styles.buyBackHolder}`}>
            <div className={`${styles.buyBackImage}`}>
                <img src="/reprise_Autofi.png" alt="Car stopped on beach" />
            </div>
            <div className={`${styles.buyBackForm}`}>
                <Form onSubmit={handleNewBuyBack} className={`container ${styles.formStyle}`}>
                    {
                    curStep == 0 ?
                        <>

                            <h3 className={`${styles.title}`}>{translator.decode(`buyBack.intro.title`)}</h3>
                            <p>{translator.decode(`buyBack.intro.description`)}</p>
                            <h3><label className={`${styles.title}`} htmlFor="nbPlate">{translator.decode(`buyBack.title.typeYourRegNg`)}</label></h3>
                            <RegNbInput enableNext={enableNext} regNb={regNb} setRegNb={setRegNb} />
                            {
                                wrongRegNb ? <div className={`${styles.wrongRegNb}`}>{translator.decode("buyBack.wrongRegNb")}</div> : null
                            }
                            <button className={`${(buttonEnable && !spinner) ? styles.buttonNext : styles.buttonStepDisabled}`} disabled={!(buttonEnable) || spinner} onClick={handleRegNbIntelRequest}>{translator.decode("dynamic.next")}</button>
                            {
                                spinner ? <LoadingScreen /> : null
                            }
                        </>
                        : null
                    }
                    {
                    curStep == 1 ?
                        <>
                            <div className={`${styles.stepOne}`}>
                                <CarValuesInput enableNext={enableNext} availableVersions={carIntels.versions ?? []} carIntelInput={carValuesIntels} setCarIntelInput={setCarValuesIntels} modelFound={carIntels.modelName} brandFound={carIntels.makeName} MECFound={new Date(carIntels.dateRelease!)}/>
                            </div>
                            <div className={`${styles.buttonPos}`}>
                                <button className={`${buttonEnable ? styles.buttonNext : styles.buttonStepDisabled}`} disabled={!buttonEnable} onClick={handleNextButton}>{translator.decode("dynamic.next")}</button>
                                <button className={`${styles.buttonStep}`} onClick={handleBackButton}>{translator.decode("dynamic.previous")}</button>
                            </div>
                        </>
                        : null
                    }
                    {
                    curStep == 2 ?
                        <>
                            <div className={`${styles.formPhotoHolder}`}>
                                <h4 className={styles.sectionTitle + " " + styles.title }>{translator.decode("buyBack.title.picture")}</h4>
                                <p style={{textAlign:"justify"}}>
                                    {translator.decode("buyBack.photoRules.photoRule1")}
                                    <br/>
                                    <br/>
                                    <span className={`${styles.photoWished}`}>{translator.decode("buyBack.photoRules.photoRule2")}</span>
                                    <br/>
                                    {translator.decode("buyBack.photoRules.photoRule3")}
                                </p>
                                <div className={`${styles.formPhoto}`}>
                                    {pictures()}
                                </div>
                                    {translator.decode("buyBack.photoRules.photoRule4")}
                                <div className={`${styles.buttonPos}`}>
                                    <button autoFocus className={`${styles.buttonNext}`} onClick={handleNextButton}>{translator.decode("dynamic.next")}</button>
                                    <button className={`${styles.buttonStep}`} onClick={handleBackButton}>{translator.decode("dynamic.previous")}</button>
                                </div>
                            </div>
                        </>
                        : null
                    }
                    {
                    curStep == 3 ?
                        <>
                            <PersonnalContact personnalIntels={personnalIntels} setPersonnalIntels={setPersonnalIntels} enableNext={enableNext} />
                            <AddressContact enableNext={enableNext} address={address} setAddress={setAddress} />
                            <div className={styles.contactForm}>
                                {/* {`${translator.decode("contact.comments")}`} */}
                                <textarea className={`${styles.commentaryField}`} value={comments} onChange={(e: any) => {setComments(e.target.value)}} name="comments" placeholder={`${translator.decode("contact.addComments")}`} rows={5} cols={80} maxLength={1024}/>
                            </div>
                            <p className={`${styles.mandatory} `}>{translator.decode(`contact.mandatory`)}</p>
                            <div className={`${styles.buttonPos}`}>
                                <button className={`${(sendEnable&& !spinner) ? styles.buttonNext : styles.buttonStepDisabled}`} disabled={!sendEnable || spinner}>{translator.decode("dynamic.send")}</button>
                                <button className={`${styles.buttonStep}`} onClick={handleBackButton}>{translator.decode("dynamic.previous")}</button>
                            </div>
                            {
                                spinner ? <LoadingScreen /> : null
                            }
                        </>
                        : null
                    }
                </Form>
                <div className={`${styles.progressBarStyle}`}>
                    <ProgressStepper curStep={curStep} barColor={""} bgColor={"rgba(0,0,0,0)"} step={4} />
                </div>
            </div>
            <ModalConfirmation show={openModal} onClose={handleToggleModal} closeModal={handleToggleModal} themeColor={""} isOkay={isOkay}/>
        </div>
    );
}
