/*
 * File: Unauthorized.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 03:29:49
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 12th January 2023 03:30:09
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

export default function Unauthorized() {
    return (
        <p>Access not allowed</p>
    );
}
