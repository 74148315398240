/*
 * File: PersonnalContact.tsx
 * Project: autofi-front
 * File Created: Monday, 6th March 2023 06:26:52
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 22nd March 2023 02:45:38
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useEffect, useRef, useState } from "react";
import translator from "MultiLanguage/TranslationWrapper";
import styles from "Content/Contact/PersonnalContact/PersonnalContact.module.css";
import { ContactSelect } from "Content/Contact/ContactSelect/ContactSelect";
import { OptionType } from "Content/Contact/ContactSelect/OptionTypeInterface";
import { PersonnalContactProps, PersonnalContactType } from "Content/Contact/PersonnalContact/PersonnalContact.type";

export default function PersonnalContact(props: PersonnalContactProps) {
    const [gender, setGender] = useState<OptionType>({label: translator.decode(`contact.genderName.select`), value: "select"});
    const [firstName, setFirstName] = useState(props.personnalIntels.firstName ?? "");
    const firstNameInput = useRef<HTMLInputElement>(null);
    const [lastName, setLastName] = useState(props.personnalIntels.lastName ?? "");
    const lastNameInput = useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState(props.personnalIntels.email ?? "");
    const emailInput = useRef<HTMLInputElement>(null);
    const [phone, setPhone] = useState(props.personnalIntels.phone ?? "");
    const phoneInput = useRef<HTMLInputElement>(null);
    const [badFirstname, setBadFirstname] = useState<boolean>(false);
    const [badLastname, setBadLastname] = useState<boolean>(false);
    const [badEmail, setBadEmail] = useState<boolean>(false);
    const [badTel, setBadTel] = useState<boolean>(false);
    const firstload = useRef(true);

    useEffect(() => {
        if (badLastname)
            lastNameInput?.current?.focus();
    }, [badLastname]);

    useEffect(() => {
        if (badEmail)
            emailInput?.current?.focus();
    }, [badEmail]);

    useEffect(() => {
        if (badTel)
            phoneInput?.current?.focus();
    }, [badTel]);

    useEffect(() => {
        if (badFirstname)
            firstNameInput?.current?.focus();
    }, [badFirstname]);

    const fieldsAreFilled = () => {
        let ret = true;
        if (firstName === undefined || firstName === "") {
            setBadFirstname(true);
            ret = false;
        }
        else if (lastName === undefined || lastName === "") {
            setBadLastname(true);
            ret = false;
        }
        else if (email === undefined || email === "") {
            setBadEmail(true);
            ret = false;
        }
        else if (phone === undefined || phone === "") {
            setBadTel(true);
            ret = false;
        }
        return ret;
    };

    const fieldsAreVerified = () => {
        let ret = true;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            if (email !== "")
                setBadEmail(true);
            ret = false;
        }
        if (!/^[\+]?[(]?[0-9]{2,3}[)]?([-\s\. ]?[0-9]{2,3}){4,6}$/.test(phone)) {
            if (phone !== "")
                setBadTel(true);
            ret = false;
        }
        return ret;
    };

    useEffect(() => {
        setBadFirstname(false);
        setBadLastname(false);
        setBadEmail(false);
        setBadTel(false);

        if (!(firstload.current) && fieldsAreVerified() && fieldsAreFilled()) {
            props.enableNext(true);
        } else {
            props.enableNext(false);
            firstload.current = false;
        }
    }, [props.personnalIntels]);

    const setGenderIntel = (e: OptionType) => {
        setGender(e);
        const tmp = props.personnalIntels;
        tmp.gender = e.value;
        props.setPersonnalIntels(tmp);
    };

    const setFirstNameIntel = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (firstName == "" && e.target.value == "")
            return;
        setFirstName(e.target.value);
        let tmp = props.personnalIntels;
        tmp = {
            firstName: e.target.value,
            lastName: props.personnalIntels.lastName,
            email: props.personnalIntels.email,
            phone: props.personnalIntels.phone,
        };
        props.setPersonnalIntels(tmp);
    };

    const setLastNameIntel = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (lastName == "" && e.target.value == "")
            return;
        setLastName(e.target.value);
        let tmp = props.personnalIntels;
        tmp = {
            firstName: props.personnalIntels.firstName,
            lastName: e.target.value,
            email: props.personnalIntels.email,
            phone: props.personnalIntels.phone,
        };
        props.setPersonnalIntels(tmp);
    };

    const setEmailIntel = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (email == "" && e.target.value == "")
            return;
        setEmail(e.target.value);
        let tmp = props.personnalIntels;
        tmp = {
            firstName: props.personnalIntels.firstName,
            lastName: props.personnalIntels.lastName,
            email: e.target.value,
            phone: props.personnalIntels.phone,
        };
        props.setPersonnalIntels(tmp);
    };

    const setPhoneIntel = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (phone == "" && e.target.value == "")
            return;
        setPhone(e.target.value);
        let tmp: PersonnalContactType = {
            firstName: props.personnalIntels.firstName,
            lastName: props.personnalIntels.lastName,
            email: props.personnalIntels.email,
            phone: e.target.value,
        };
        props.setPersonnalIntels(tmp);
    };

    return (
        <>
        <h3 className={`${styles.title}`}>{translator.decode("contact.personnalIntels")}</h3>
        <div className={`${styles.lineBlock}`}>
            <div className={`${styles.inputBlock}`}>
                {/* <label className={`${styles.input}`} htmlFor="gender">{translator.decode("contact.gender")}:</label> */}
                    <ContactSelect className={`${styles.input} ${styles.inputSelect}`} id="gender" value={gender}
                                    onChange={setGenderIntel} options={["select", "male", "female", "other"]} contactSubCategory="contact.genderName" />
            </div>
            <div className={`${styles.inputBlock}`}>
                {/* <label className={`${styles.input}`} htmlFor="firstname">{translator.decode("contact.firstname")}<span className={styles.mandatory}>*</span> :</label> */}
                <div style={{display:"flex"}}>
                    <input autoFocus={true} ref={firstNameInput} className={`${styles.input} ${styles.inputSize} ${badFirstname ? styles.wrongInput : null}`}
                            id="firstname" type="text" name="fistname" placeholder={translator.decode("contact.firstname")}
                            value={firstName} onBlur={setFirstNameIntel} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} />
                    <span className={styles.mandatory}>*</span>
                </div>
                {
                  badFirstname ? <p className={styles.mandatory}>{translator.decode("contact.wrong.firstName")}</p> : null
                }
            </div>
            <div className={`${styles.inputBlock}`}>
                {/* <label className={`${styles.input}`} htmlFor="lastname">{translator.decode("contact.lastname")}<span className={styles.mandatory}>*</span> :</label> */}
                <div style={{display:"flex"}}>
                    <input ref={lastNameInput} className={`${styles.input} ${styles.inputSize} ${badLastname ? styles.wrongInput : null}`}
                            id="lastname" type="text" name="lastname" placeholder={translator.decode("contact.lastname")}
                            value={lastName} onBlur={setLastNameIntel} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
                    <span className={styles.mandatory}>*</span>
                </div>
                {
                  badLastname ? <p className={styles.mandatory}>{translator.decode("contact.wrong.lastName")}</p> : null
                }
            </div>
        </div>
        <div className={`${styles.lineBlock}`}>
            <div className={`${styles.inputBlock}`}>
                {/* <label className={`${styles.input}`} htmlFor="email">Email<span className={styles.mandatory}>*</span> :</label> */}
                <div style={{display:"flex"}}>
                    <input ref={emailInput} required={true}
                            className={`${styles.input} ${styles.inputSize} ${badEmail ? styles.wrongInput : null}`}
                            id="email" type="email" name={email} placeholder="email@contact.com"
                            onBlur={setEmailIntel} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                    <span className={styles.mandatory}>*</span>
                </div>
                {
                  badEmail? <p className={styles.mandatory} >{translator.decode("contact.wrong.email")}</p> : null
                }
            </div>
            <div className={`${styles.inputBlock}`}>
                {/* <label className={`${styles.input}`} htmlFor="tel">Tel<span className={styles.mandatory}>*</span> :</label> */}
                <div style={{display:"flex"}}>
                    <input ref={phoneInput} className={`${styles.input} ${styles.inputSize} ${badTel ? styles.wrongInput : null}`}
                        id="tel" type="tel" name={phone} placeholder="+336 55 55 55 55"
                        onBlur={setPhoneIntel} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)} />
                    <span className={styles.mandatory}>*</span>
                </div>
                {
                  badTel ? <p className={styles.mandatory}>{translator.decode("contact.wrong.phone")}</p> : null
                }
            </div>
            <div className={`${styles.inputBlock}`}>
                <p style={{display: "none"}}>This is only there for the show.</p>
            </div>
        </div>
        </>
    )
}
