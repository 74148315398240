/*
 * File: SelectGender.tsx
 * Project: autofi-front
 * File Created: Tuesday, 28th February 2023 04:31:24
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 13th March 2023 04:27:49
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { SelectProps } from "Content/Contact/ContactSelect/SelectPropsInterface";
import translator from "MultiLanguage/TranslationWrapper";
import { ActionMeta, SingleValue } from "react-select";
import { OptionType } from "Content/Contact/ContactSelect/OptionTypeInterface";
import Select from "Theme/Component/Select";

export function ContactSelect(props: SelectProps) {
  return (
    <Select styles={{
      control: (provided: any[], state: any) => ({
        ...provided,
        boxShadow: "none",
        borderRadius: 0,
        border: "none"
      }),
      menu: (provided: any[], state: any) => ({
        ...provided,
        border: "none",
        boxShadow: "0 0 0 1px var(--theme-color)"
      }),
      option: (provided: any[], state: any) => ({
          ...provided,
          backgroundColor: state.isFocused && "var(--theme-color)",
          color: state.isFocused && "var(--theme-bg-color)",
      })
    }}
    className={props.className}
    disabled={props.disabled}
    id={props.id}
    value={props.value}
    options={props.options && Array.isArray(props.options) && (props.options as any[]).every((it: string | OptionType) => typeof it === 'string') ? (props.options as any[]).map((v: string) => { return {label: props.contactSubCategory != undefined ? translator.decode(`${props.contactSubCategory}.${v}`) as string : v, value: v}}) : (props.options ? props.options as OptionType[]: [])}
    onChange={(e: SingleValue<OptionType>, action: ActionMeta<SingleValue<OptionType>>) => { props.onChange(e != null ? e : props.value) }}
    isSearchable={props.searchable} />
  );
}
