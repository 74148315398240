/*
 * File: TranslationWrapper.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

import Translator from "MultiLanguage/Translation";

/**
 * Factory to create translation instance Object
 * @param {Object} target The instance of translation
 */
const TranslatorFactory = (target: Translator) => ({
  setLanguage: (language: string) => target.setLanguage(language),
  getLanguage: () => target.getLanguage(),
  getAvailableLanguages: () => target.getAvailableLanguages(),
  decode: (treePosition: string) => {
    try {
      return target.decode(treePosition);
    } catch {
      return treePosition;
    }
  },
});

const translator = TranslatorFactory(new Translator());

export default translator;
