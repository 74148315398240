/*
 * File: Input.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import styled from "styled-components";
import Select from "react-select";

/**
* Styled component Input
* Type: input (provided via props)
*/

const mySelect = styled(Select).attrs(props => (
{
    options: props.options,
    className: `medium_font_2 ${props.className}`,
    placeholder: props.placeholder,
    isDisabled: ((props.options !== undefined && props.options?.length > 1 ? false : true) || (props.disabled ?? false))
}
))`
color: var(--theme-bg-color);
background-color: ${props => props.theme.colors.first};
font-size: 0.9rem;
letter-spacing: 0.5px;
border: 0;
border-bottom: 1px solid ${props => props.theme.colors.first};
&:hover, &:focus {
    outline: none;
    background-color: ${props => props.theme.colors.first};
}
`;

export default mySelect;
