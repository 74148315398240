/*
 * File: NotFound.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 03:28:59
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 12th January 2023 03:29:36
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

export default function NotFound() {
    return (
        <p>Page not found</p>
    );
}

