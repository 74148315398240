/*
 * File: CarValuesInput.tsx
 * Project: autofi-front
 * File Created: Friday, 17th March 2023 06:03:52
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 24th March 2023 04:54:23
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { SelectChangeEvent } from "@mui/material";
import { CarValuesInputProps } from "Content/BuyBack/CarValuesInput/CarValuesInput.type";
import translator from "MultiLanguage/TranslationWrapper";
import styles from "Content/BuyBack/CarValuesInput/CarValuesInput.module.css";
import Input from "Theme/Component/Input";
import SelectStyled from "Theme/Component/SelectStyled";

export function CarValuesInput(props: CarValuesInputProps) {

    const handleSelectValueChange= (e: SelectChangeEvent) => {
        if (e.target.value !== "0") {
            const ret = props.availableVersions.find((v) => v.id?.toString() == e.target.value)
            if (ret) {
                props.carIntelInput.version = ret;
                props.setCarIntelInput(props.carIntelInput);
                if (props.carIntelInput.mileage)
                    props.enableNext();
            }
        }
    };

    const handleInputChange= (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = 0;
        input = parseInt(e.target.value);

        if (input && input > 0) {
            props.carIntelInput.mileage = input;
            props.setCarIntelInput(props.carIntelInput);
            if (props.carIntelInput.version)
                props.enableNext();
        }
    };

    return (
        <>
            <h3 className={`${styles.title}`}>{translator.decode("buyBack.title.chooseVersion")}</h3>
            <p>
                <span>{translator.decode("buyBack.placeholder.brand")}: {props.brandFound}</span>
                <br />
                <span>{translator.decode("buyBack.placeholder.model")}: {props.modelFound}</span>
                <br />
                <span>{translator.decode("buyBack.MEC")} {props.MECFound?.toLocaleDateString()}</span>
            </p>
            <label className={styles.contentLayout}>
                {/* {translator.decode("buyBack.chooseVersion")} */}
                <SelectStyled borderWidth={"1px"} id="selectVersion" onChange={handleSelectValueChange}>
                    <option key="default" value={0}>{translator.decode("buyBack.selectVersion")}</option>
                    {
                        props.availableVersions.map(
                            (v) => {
                                return <option value={v.id?.toString()} key={v.id?.toString()}>{v.name}</option>;
                            }
                        )
                    }
                </SelectStyled>
            </label>
            <label className={styles.contentLayout}>
                {translator.decode("buyBack.tellMileage")}
                <div>
                    <Input align="right" variant="none" onChange={handleInputChange}/>
                    Km
                </div>
            </label>
        </>
    );
}
