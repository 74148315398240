/*
 * File: AdressService.ts
 * Project: autofi-front
 * File Created: Thursday, 2nd March 2023 05:12:55
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd March 2023 03:52:15
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */
import { buildQueryString } from "build-url-ts";
import { OptionType } from "Content/Contact/ContactSelect/OptionTypeInterface";

type QueryParams = { [key: string]: string | string[] };

export const getAddresses = async (input: string) => {
  const addr: QueryParams = { limit: "15" };

  addr["q"] = input.replaceAll(" ", "+");
  const query = buildQueryString(addr);
  const url = "https://api-adresse.data.gouv.fr/search" + query;
  const tmp = await fetch(url);
  const result = await tmp.json();
  const propositions: OptionType[] = [];
  if (result["features"] !== undefined) {
    for (const res in result["features"]) {
      const feat = result["features"][res];
      const prop = feat["properties"];
      if (feat !== undefined && prop !== undefined)
        propositions.push({
          label: prop["label"],
          value: prop["label"],
          data: {
            houseNumber: prop["housenumber"],
            street: prop["street"],
            postalCode: prop["postcode"],
            city: prop["city"],
            score: prop["score"],
          },
        });
    }
  }
  return propositions;
};
