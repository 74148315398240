/*
 * File: InputSubmit.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import styled from "styled-components";

/**
* Styled component Input submit
* Type: input (provided via props)
*/
const Input = styled.input.attrs(props => ({
    type: "submit",
    className: "small-round-border medium_font_2",
}))`
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
padding-left: 10px;
color: ${props => props.variant === "positive" ? props.theme.colors.fifth : (props.variant === "negative" ? props.theme.colors.fifth : props.theme.colors.first)};
background-color: var(--white);
border: 2px solid transparent;
box-shadow:
0.2rem 0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.044),
0.4rem 0.4rem 0.5rem 0.1rem rgba(0, 0, 0, 0.066),
1rem 1rem 1.5rem 0.4rem rgba(0, 0, 0, 0.11);
&:hover {
    color:var(--white);
    background-color: ${props => props.variant === "positive" ? props.theme.colors.sixth : (props.variant === "negative" ? props.theme.colors.second: (props.variant === "warning" ? props.theme.colors.seventh : props.theme.colors.first))};
}
`;

export default Input;
