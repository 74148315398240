/*
 * File: DepartmentListe.ts
 * Project: autofi-front
 * File Created: Monday, 6th March 2023 03:46:45
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 6th March 2023 03:51:58
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

const depValues = [
  { label: "Ain", value: "01" },
  { label: "Aisne", value: "02" },
  { label: "Allier", value: "03" },
  { label: "Alpes-de-Haute-Provence", value: "04" },
  { label: "Hautes-Alpes", value: "05" },
  { label: "Alpes-Maritimes", value: "06" },
  { label: "Ardèche", value: "07" },
  { label: "Ardennes", value: "08" },
  { label: "Ariège", value: "09" },
  { label: "Aube", value: "10" },
  { label: "Aude", value: "11" },
  { label: "Aveyron", value: "12" },
  { label: "Bouches-du-Rhône", value: "13" },
  { label: "Calvados", value: "14" },
  { label: "Cantal", value: "15" },
  { label: "Charente", value: "16" },
  { label: "Charente-Maritime", value: "17" },
  { label: "Cher", value: "18" },
  { label: "Corrèze", value: "19" },
  { label: "Côte-d'Or", value: "21" },
  { label: "Côtes-d'Armor", value: "22" },
  { label: "Creuse", value: "23" },
  { label: "Dordogne", value: "24" },
  { label: "Doubs", value: "25" },
  { label: "Drôme", value: "26" },
  { label: "Eure", value: "27" },
  { label: "Eure-et-Loir", value: "28" },
  { label: "Finistère", value: "29" },
  { label: "Corse-du-Sud", value: "2A" },
  { label: "Haute-Corse", value: "2B" },
  { label: "Gard", value: "30" },
  { label: "Haute-Garonne", value: "31" },
  { label: "Gers", value: "32" },
  { label: "Gironde", value: "33" },
  { label: "Hérault", value: "34" },
  { label: "Ille-et-Vilaine", value: "35" },
  { label: "Indre", value: "36" },
  { label: "Indre-et-Loire", value: "37" },
  { label: "Isère", value: "38" },
  { label: "Jura", value: "39" },
  { label: "Landes", value: "40" },
  { label: "Loir-et-Cher", value: "41" },
  { label: "Loire", value: "42" },
  { label: "Haute-Loire", value: "43" },
  { label: "Loire-Atlantique", value: "44" },
  { label: "Loiret", value: "45" },
  { label: "Lot", value: "46" },
  { label: "Lot-et-Garonne", value: "47" },
  { label: "Lozère", value: "48" },
  { label: "Maine-et-Loire", value: "49" },
  { label: "Manche", value: "50" },
  { label: "Marne", value: "51" },
  { label: "Haute-Marne", value: "52" },
  { label: "Mayenne", value: "53" },
  { label: "Meurthe-et-Moselle", value: "54" },
  { label: "Meuse", value: "55" },
  { label: "Morbihan", value: "56" },
  { label: "Moselle", value: "57" },
  { label: "Nièvre", value: "58" },
  { label: "Nord", value: "59" },
  { label: "Oise", value: "60" },
  { label: "Orne", value: "61" },
  { label: "Pas-de-Calais", value: "62" },
  { label: "Puy-de-Dôme", value: "63" },
  { label: "Pyrénées-Atlantiques", value: "64" },
  { label: "Hautes-Pyrénées", value: "65" },
  { label: "Pyrénées-Orientales", value: "66" },
  { label: "Bas-Rhin", value: "67" },
  { label: "Haut-Rhin", value: "68" },
  { label: "Rhône", value: "69" },
  { label: "Haute-Saône", value: "70" },
  { label: "Saône-et-Loire", value: "71" },
  { label: "Sarthe", value: "72" },
  { label: "Savoie", value: "73" },
  { label: "Haute-Savoie", value: "74" },
  { label: "Paris", value: "75" },
  { label: "Seine-Maritime", value: "76" },
  { label: "Seine-et-Marne", value: "77" },
  { label: "Yvelines", value: "78" },
  { label: "Deux-Sèvres", value: "79" },
  { label: "Somme", value: "80" },
  { label: "Tarn", value: "81" },
  { label: "Tarn-et-Garonne", value: "82" },
  { label: "Var", value: "83" },
  { label: "Vaucluse", value: "84" },
  { label: "Vendée", value: "85" },
  { label: "Vienne", value: "86" },
  { label: "Haute-Vienne", value: "87" },
  { label: "Vosges", value: "88" },
  { label: "Yonne", value: "89" },
  { label: "Territoire de Belfort", value: "90" },
  { label: "Essonne", value: "91" },
  { label: "Hauts-de-Seine", value: "92" },
  { label: "Seine-Saint-Denis", value: "93" },
  { label: "Val-de-Marne", value: "94" },
  { label: "Val-d'Oise", value: "95" },
  { label: "Guadeloupe", value: "971" },
  { label: "Martinique", value: "972" },
  { label: "Guyane", value: "973" },
  { label: "La Réunion", value: "974" },
  { label: "Mayotte", value: "976" },
];
export default depValues;
