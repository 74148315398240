/*
 * File: CarService.ts
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 04:55:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 28th March 2023 10:16:59
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import PictureService from "Services/Pictures/PictureService";
import {
  BuyBackRequest,
  RespBuyBackReqDbType,
} from "Services/BuyBack/BuyBackType";

export default class BuyBackService {
  static async addNewBuyBackRequest(
    buyback: BuyBackRequest
  ): Promise<RespBuyBackReqDbType | null> {
    const buyBackPics = buyback.pictures;
    buyback.pictures = undefined;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(buyback),
    };
    const res = await fetch(
      `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.POST_REQUEST_BUYBACK}`,
      requestOptions
    );
    if (!res.ok) return null;
    const picArray: any[] = [];
    const carPromise = res.json() as Promise<RespBuyBackReqDbType>;
    if (buyBackPics !== undefined) {
      for (let i = 0; i < buyBackPics.length; ++i) {
        const ret = await PictureService.uploadPicture(
          buyBackPics[i],
          carPromise,
          `pic-${i.toLocaleString("fr-fr", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`,
          picArray
        );
        await new Promise((r) => setTimeout(r, 2000));
      }
    }
    const car = await carPromise;
    PictureService.propagateEmails(car.id);
    return car;
  }
}
