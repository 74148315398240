/*
 * File: ImageUpload.ts
 * Project: autofi-front
 * File Created: Monday, 20th March 2023 12:22:12
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 24th March 2023 06:20:18
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import styles from "Content/BuyBack/BuyBack.module.css";
import {useEffect, useState} from "react";
import { ImageUploadProps } from "Content/BuyBack/ImageUpload/ImageUpload.type";
import DropZone from "Theme/Component/DropZone";
import { BsPlusCircle } from "react-icons/bs";
import translator from "MultiLanguage/TranslationWrapper";

function ImageUpload(props: ImageUploadProps) {
    const [preview, setPreview] = useState("");
    const [curFile, setCurFile] = useState<File>();

    const onSelectFile = (e: any) => {
        props.onChange(e.target.files[0], true);
        setCurFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    };

    useEffect(() => {
        if (props.file !== undefined) {
            setCurFile(props.file);
            const objectUrl = URL.createObjectURL(props.file);
            setPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        } else
            setPreview("");
    }, [props.file]);

    const previewFile = () => {
        return (<>
                <img src={preview} alt="" className={styles.inputImg}/>
                <button className={`${styles.closeButton}`} onClick={()=>{props.onChange(curFile, false)}}>X</button>
                </>
        );
    };

    return (
        <div className={`file-container ${props.className}`}>
            {
                preview === "" &&
                <p className={"picture-input"}>
                    <DropZone>
                        <BsPlusCircle size={"3rem"} fill="var(--theme-color)"/>
                        <p>{translator.decode("buyBack.addPicture")}</p>
                        <input type="file" id={props.inputId} accept="image/*" onChange={onSelectFile} required={props.required} />
                    </DropZone>
                </p>
            }
            { preview !== "" && previewFile() }
        </div>
    );
}

export default ImageUpload;
