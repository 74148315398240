/*
 * File: Theme.light.ts
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

/**
 * Config of the light theme
 */

import {
  MyFonts,
  MyImages,
  MyColors,
  ThemeType,
} from "Config/Theme/Theme.type";

const colors: MyColors = {
  first: "#f1a200",
  second: "#BDD138",
  third: "#46abe1",
  fourth: "#ffda00",
  fifth: "#9492f7",
  sixth: "#e35d9e",
  first20: "rgba(240, 160, 0, 0.2)",
  second20: "rgba(189, 209, 56, 0.2)",
  third20: "rgba(70, 171, 225, 0.2)",
  fourth20: "rgba(255, 218, 0, 0.2)",
  fifth20: "rgba(231, 57, 56, 0.2)",
  sixth20: "rgba(227, 93, 158, 0.2)",
  transparent: "rgba(0,0,0,0)",
};

const images: MyImages = {
  france: "/images/france.png",
};

const logos: MyImages = {
  logo_horizontal: "/logos/logo.png",
};

const ThemeConf: ThemeType = {
  colors,
  logos,
  fonts: null,
  images,
};

export default ThemeConf;

declare module "@mui/joy/Slider" {
  interface SliderPropsColorOverrides {
    sedan: true;
    break: true;
    citycar: true;
    suv: true;
    utility: true;
    neutre: true;
  }
}
