/*
 * File: RegNbInput.tsx
 * Project: autofi-front
 * File Created: Thursday, 16th March 2023 04:41:35
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd March 2023 02:02:42
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import RegNbInputProps from "Content/BuyBack/RegNbInput/RegNbInput.type";
import { useState } from "react";
import styles from "Content/BuyBack/RegNbInput/RegNbInput.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import Input from "Theme/Component/Input";

function RegNbInput(props: RegNbInputProps) {
    const [errMsg, setErrMsg] = useState("");
    const myRegNew = /[a-zA-Z]{2}\d{3}[a-zA-Z]{2}/;
    const myRegOld = /[0-9]{2,4}[a-zA-Z]{2,3}(\d{2}|2A|2B)/;

    const handleRegNbChanges = (e: any): boolean => {
        let curRegNb: string = e.target.value;
        curRegNb = curRegNb.replaceAll("-", "");
        curRegNb = curRegNb.replaceAll(" ", "");
        curRegNb = curRegNb.toUpperCase();
        const isNewPlate = myRegNew.test(curRegNb);
        const isOldPlate = myRegOld.test(curRegNb);

        if (!isNewPlate && !isOldPlate) {
            setErrMsg(translator.decode("buyBack.wrongPlateSize"));
            return false;
        }
        return true;
    };

    const updateText = (e: any) => {
        let immat: string = e.target.value;

        setErrMsg("");
        props.enableNext(false);
        if (props.regNb.length < immat.length) {
            if (/^[a-zA-Z].*/.test(immat)) {
                switch (immat.length) {
                    case 2:
                    case 6:
                        immat += "-";
                        break;
                    case 9:
                        props.enableNext(handleRegNbChanges(e))
                        break;
                    default:
                        break;
                }
            } else if (/^[0-9].*/.test(immat)) {
                const ln = immat.length;
                const firstDash = ln > 2 && ln <= 5 && /[a-zA-Z]/.test(immat.charAt(ln - 1)) && /[0-9]/.test(immat.charAt(ln - 2));
                const secondDash = ln > 4 && ln <= 8 && /[a-zA-Z]/.test(immat.charAt(ln - 2)) && /[0-9]/.test(immat.charAt(ln - 1));
                if (firstDash || secondDash) {
                    let tmp = immat.slice(0, ln - 1);
                    tmp += "-";
                    tmp += immat.charAt(ln - 1);
                    immat = tmp;
                }
                if (ln >= 8)
                    props.enableNext(handleRegNbChanges(e));
            }
        }
        props.setRegNb(immat.toUpperCase());
    };

    return (
        <div className={`${styles.contentLayouyt}`}>
            <div className={`${styles.regNb}`}>
                <img alt="Picture of french registration plate" className={`${styles.noSpace}`} src={`${process.env.PUBLIC_URL}/regPlate1.png`} />
                <Input autoFocus={true} maxLength={10} id="nbPlate" onBlur={(e:any) => props.enableNext(handleRegNbChanges(e))} className={`${styles.nbPlate}`} value={props.regNb} onChange={updateText}/>
                <img alt="Picture of french registration plate" className={`${styles.noSpace}`} src={`${process.env.PUBLIC_URL}/regPlate2.png`}/>
            </div>
            {
                errMsg != "" ?
                    <p className={styles.mandatory}>{errMsg}</p>
                    : null
            }
        </div>
    );
}

export default RegNbInput;
