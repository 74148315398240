/*
 * File: CarService.ts
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 04:55:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 5th May 2023 05:28:36
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import { CarListable } from "Content/Car/CarList/CarList.type";
import { buildUrl } from "build-url-ts";
import { SingleCarFormat } from "Content/Car/CarElement/CarElement.type";
import axios from "axios";
import Cookie from "Cookies/CookieWrapper";
import CookiePathResolver from "Config/Cookies/CookiePathResolver";

type QueryParams = { [key: string]: string | string[] };
type RespCarDbType = {
  cars: string[];
  nb_page: number;
  brand: string;
};
type calcRent = {
  rent: number;
};
type RespSingleCarDbType = {
  id: number;
  RegNb: string;
  VIN: string;
  Brand: string;
  Model: string;
  Version: string;
  Mileage: number;
  Color: string;
  Energy: string;
  VAT: boolean;
  CirculationDate: Date;
  Gears: string;
  SellPrice: number;
  ParkID: number;
  TypeID: number;
  CarType: {
    Abreviation: string;
    Name: string;
    id: number;
  };
  StoragePlace: {
    Address: string;
    City: string;
    Country: string;
    Name: string;
    PostalCode: number;
    State: string;
    id: number;
  };
  Status: string;
};

export default class CarService {
  static async getCars(
    page: number,
    filters: string[] | null | undefined,
    orderBy: { name: string; order: string } | undefined,
    isPromo?: boolean | undefined
  ): Promise<any> {
    const a: QueryParams = {};
    filters = filters?.filter((val: string) => val != "");
    if (page > 0) a.page = page.toString();
    if (filters != null && filters != undefined && filters.length > 0)
      a.filters = filters.toString();
    if (
      orderBy != undefined &&
      orderBy.name != "" &&
      orderBy.order != "" &&
      orderBy.order != "notActivated"
    ) {
      let name = orderBy.name;
      switch (orderBy.name) {
        case "sell_price":
          name = "SellPrice";
          break;
        case "mileage":
          name = "Mileage";
          break;
        case "circulation_date":
          name = "CirculationDate";
          break;
        case "rental_cost":
          name = "RentalCost";
          break;
        case "id":
          name = "id";
          break;
        default:
          break;
      }
      a.orderBy = name + "," + orderBy.order;
    }
    if (isPromo) a.isPromo = isPromo.toString();

    const builtUrl = buildUrl(
      `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_CARS}`,
      {
        queryParams: {
          page: a.page,
          filters: a.filters,
          orderedby: a.orderBy,
          isPromo: a.isPromo,
        },
      }
    );
    const myList: any = await CarService.callApi(builtUrl);
    if (myList.cars == undefined) {
      return { nb_page: 0, cars: [], brand: "" };
    }
    return CarService.transformCarList(myList);
  }

  static async callApi(url: string) {
    let tmp: any;
    let myRet: any = {};
    try {
      tmp = await fetch(url);
    } catch {
      return myRet;
    }
    if (tmp.ok) {
      myRet = tmp.json();
    }
    return myRet;
  }

  static async transformCarList(list: RespCarDbType) {
    let myCars: CarListable[] = [];
    let nbPages = 0;
    let brand = "";
    if (list.cars != undefined) {
      myCars = list.cars.map((x: any) => {
        const tmp2 = new CarListable();
        tmp2.fromJson(x);
        return tmp2;
      });
      nbPages = list.nb_page;
    }
    if (list.brand != undefined) brand = list.brand;

    return { nb_page: nbPages, cars: myCars, brand: brand };
  }

  static async transformSingleCar(car: any): Promise<SingleCarFormat | null> {
    if (car == undefined || car == null) return null;
    const newCar = new SingleCarFormat();
    newCar.fromJson(car);
    return newCar;
  }

  static async getSingleCar(id: number): Promise<any> {
    const singleCar = CarService.callApi(
      Config.BACKEND.BASEURL +
        Config.BACKEND.PATHS.GET_CARS +
        "/" +
        id.toString()
    ) as Promise<RespSingleCarDbType>;
    const mySingleCar = CarService.transformSingleCar(await singleCar);

    return mySingleCar;
  }

  static getPicturesForOneCar(id: number) {
    return CarService.callApi(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_PICTURE + id.toString()
    );
  }

  static getEquipementsForOneCar(id: number) {
    return CarService.callApi(
      Config.BACKEND.BASEURL +
        Config.BACKEND.PATHS.GET_CAR_EQUIPMENT +
        id.toString()
    );
  }

  static async getCarsFromCart(array: number[]): Promise<any> {
    const respCartList: SingleCarFormat[] = [];
    const myParams = buildUrl("", {
      queryParams: {
        cars: array,
      },
    });

    const myList = await (CarService.callApi(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_CART + myParams
    ) as Promise<RespSingleCarDbType[]>);
    if (myList != undefined && myList.length > 0)
      myList.map(async (car: any) => {
        const tmp = await CarService.transformSingleCar(car);
        if (tmp != null) respCartList.push(tmp);
      });
    return respCartList;
  }

  static setQueryParams(filters: string[]) {
    const a: QueryParams = {};
    if (filters != null && filters.length! > 0) a.filters = filters.toString();
    const myParams = buildUrl("", {
      queryParams: {
        filters: a.filters,
      },
    });
    return myParams;
  }

  static async getCarCategoriesAvailable(): Promise<string[]> {
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_CATEGORIES
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarEnergiesAvailable(filters: string[]): Promise<string[]> {
    const myParams = this.setQueryParams(filters);
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_ENERGIES + myParams
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarBrandsAvailable(filters: string[]): Promise<string[]> {
    const myParams = this.setQueryParams(filters);
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_BRANDS + myParams
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarModelsAvailable(filters: string[]): Promise<string[]> {
    const myParams = this.setQueryParams(filters);
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_MODELS + myParams
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarsByBrand(
    brand: string,
    page: number | null,
    orderBy: { name: string; order: string }
  ): Promise<any> {
    const a: QueryParams = {};

    if (page != null && page > 0) a.page = page.toString();
    if (
      orderBy.name != "" &&
      orderBy.order != "" &&
      orderBy.order != "notActivated"
    ) {
      let name = orderBy.name;
      switch (orderBy.name) {
        case "sell_price":
          name = "SellPrice";
          break;
        case "mileage":
          name = "Mileage";
          break;
        case "circulation_date":
          name = "CirculationDate";
          break;
        case "rental_cost":
          name = "RentalCost";
          break;
        default:
          break;
      }
      a.orderBy = name + "," + orderBy.order;
    }
    const myParams = buildUrl("", {
      queryParams: {
        page: a.page,
        orderedby: a.orderBy,
      },
    });

    const tmp = await fetch(
      Config.BACKEND.BASEURL +
        Config.BACKEND.PATHS.GET_CARS_BY_BRAND +
        brand +
        myParams
    );
    const myRet = await tmp.json();

    return CarService.transformCarList(myRet);
  }

  static async sendCarFile(file: File) {
    const myToken = Cookie.getCookie(CookiePathResolver.USER.TOKEN);
    const formData = new FormData();
    formData.append("cars", file);
    const config = {
      headers: {
        Authorization: `Bearer ${myToken.access_token}`,
        "content-type": "multipart/form-data",
      },
    };
    const myRet = await axios.put(
      `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.SEND_CARFILE}`,
      formData,
      config
    );
    return await myRet.data;
  }

  static async sendPicZip(formData: FormData) {
    const myToken = Cookie.getCookie(CookiePathResolver.USER.TOKEN);
    const config = {
      headers: {
        Authorization: `Bearer ${myToken.access_token}`,
        "content-type": "multipart/form-data",
      },
    };

    const myRet = await axios.put(
      `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.SEND_PICZIP}`,
      formData,
      config
    );
    return await myRet.data;
  }

  static async getRentalCost(
    contribution: number,
    sellPrice: number,
    mileageYear: number,
    duration: number
  ): Promise<calcRent> {
    const a: QueryParams = {};

    const myUrlParams = buildUrl(
      `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.RENTAL_CALC}`,
      {
        queryParams: {
          contribution: contribution,
          sellPrice: sellPrice,
          mileageYear: mileageYear,
          duration: duration,
        },
      }
    );
    const tmp = await fetch(myUrlParams);
    const myRet = await tmp.json();
    return myRet;
  }
}
