/*
 * File: UserSession.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

import Cookie from "Cookies/CookieWrapper";
import CookiePathResolver from "Config/Cookies/CookiePathResolver";
import Config from "Config/Config";
import Axios, { AxiosResponse } from "axios";

/**
 * Class used to manage session user in cookies
 */
class UserSession {
  /**
   * Get The user informations as an object
   * @returns The info of the user connected
   */
  static getUser() {
    return Cookie.getCookie(CookiePathResolver.USER.FIELD_USER);
  }

  /**
   * Get the token of the user
   * @returns The token as a string
   */
  static getAccessToken() {
    return Cookie.getCookie(CookiePathResolver.USER.TOKEN);
  }

  /**
   * Get the refresh token of the user
   * @returns The token as a string
   */
  static getRefreshToken() {
    return Cookie.getCookie(CookiePathResolver.USER.REFRESH_TOKEN);
  }

  /**
   * Set the data of the users
   * @param {Object} data The data of the user
   */
  static getId() {
    Cookie.getCookie(CookiePathResolver.USER.FIELD_USER_ID);
  }

  /**
   * Set the data of the users
   * @param {Object} data The data of the user
   */
  static setId(data: number) {
    Cookie.setCookie(CookiePathResolver.USER.FIELD_USER_ID, data);
  }

  /**
   * Set the data of the users
   * @param {Object} data The data of the user
   */
  static setUser(data: any) {
    Cookie.setCookie(CookiePathResolver.USER.FIELD_USER, data);
  }

  /**
   * Set the data of the role
   * @param {Object} data The data of the user
   */
  static setRole(data: string) {
    Cookie.setCookie(CookiePathResolver.USER.ROLE, data);
  }

  /**
   * Set the tokens of the users
   * @param {Object} tokens The tokens of the user
   */
  static setTokens(tokens: {
    access_token: { token: string; expires_at: string };
    refresh_token: { token: string; expires_at: string };
  }) {
    Cookie.setCookie(CookiePathResolver.USER.TOKEN, tokens.access_token);
    if (tokens.refresh_token !== undefined && tokens.refresh_token !== null)
      Cookie.setCookie(
        CookiePathResolver.USER.REFRESH_TOKEN,
        tokens.refresh_token
      );
  }

  /**
   * Get the role of the authenticated user, null otherwise
   * @returns The role of the authenticated user, null otherwise
   */
  static getRole() {
    return Cookie.getCookie(CookiePathResolver.USER.ROLE);
  }

  /**
   * Check if the current session is active
   * @returns True if logged, false otherwise
   */
  static isUserLoggedIn() {
    const tokens = Cookie.getCookie(CookiePathResolver.USER.TOKEN);
    if (tokens !== undefined) {
      return true;
    }
    return false;
  }

  /**
   * Remove cookies of the authenticated user
   */
  static signOut() {
    Cookie.removeCookie(CookiePathResolver.USER.TOKEN);
    Cookie.removeCookie(CookiePathResolver.USER.FIELD_USER);
    Cookie.removeCookie(CookiePathResolver.USER.ROLE);
  }

  static async timeOutSession() {
    const refrToken = UserSession.getRefreshToken();
    const usrId = UserSession.getUser();
    const url = `${Config.BACKEND.BASEURL + Config.BACKEND.PATHS.LOGIN}/${
      usrId.id
    }/refresh`;
    const resp = await Axios.post(url, {
      refresh_token: refrToken.refresh_token,
    }).catch((error) => {
      UserSession.signOut();
      return false;
    });
    if (!(typeof resp === "boolean") && resp.status === 200) {
      UserSession.setTokens({
        access_token: resp.data.token,
        refresh_token: resp.data.refreshToken,
      });
      UserSession.setRole(resp.data.role);
      UserSession.setId(resp.data.user_id);
      return true;
    }
    return false;
  }
}

export default UserSession;
