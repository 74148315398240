/*
 * File: LoadingScreen.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

import React from "react";
import styles from "Content/LoadingScreen/LoadingScreen.module.css";
import Spinner from "react-bootstrap/Spinner";

function LoadingScreen() {
    return (
        <React.Fragment>
            <div id={styles.divBackground} className={"v-center-content h-center-content"}>
                <Spinner animation="border" id={styles.spinner}/>
            </div>
        </React.Fragment>
    );
}

export default LoadingScreen;