import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
// Import for to the Global THEME
import Theme from "Theme/Theme";
import "Theme/Theme.css";
// Import for the router
import { Context } from "Utils/Context";
import { Router } from "Router/Router";
// Import for css bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "index.css";
import translator from 'MultiLanguage/TranslationWrapper';
import useFavIds from 'Hooks/UseFavIds';

function AppComponent() {
  const favIds = useFavIds();
  const [ language, setLanguage ] = useState(translator.getLanguage() === null || translator.getLanguage() === undefined ? "fr" : translator.getLanguage());
  const idsCart: any | null  = favIds;

  return (
    <React.Fragment>
      <Context.Provider value={ { language, setLanguage, idsCart } }>
        <Theme pendingComponent={LoadingScreen}>
            <RouterProvider router={Router}/>
        </Theme>
      </Context.Provider>
    </React.Fragment>
  );
}

ReactDOM.render(
  // <React.StrictMode>
    <AppComponent />
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
