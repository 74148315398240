/*
 * File: Button.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import styled from "styled-components";

/**
* Styled component Button
*/
const Button = styled.button.attrs(props => ({
    variant: props.variant,
    className: "font-weight-900 extra-lineheight-1 medium-font-2 " + props.className,
}))`
padding-right: 10px;
padding-left: 10px;
border-radius: 5px;
border: 0;
background-color: rgba(0,0,0,0);
color: ${props => props.variant === "positive" ? props.theme.colors.fifth : (props.variant === "negative" ? props.theme.colors.fifth : props.theme.colors.fifth)};
&:hover, &:focus {
}
`;

export default Button;
