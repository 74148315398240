/*
 * File: PicturesContainer.ts
 * Project: autofi-front
 * File Created: Monday, 20th March 2023 12:06:24
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 24th March 2023 06:09:33
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useEffect, useState, ReactNode } from "react";
import { PicturesContainerProps } from "Content/BuyBack/PicturesContainer/PicturesContainerProps.type";
import Picture from "Content/BuyBack/Picture/Picture";
import translator from "MultiLanguage/TranslationWrapper";

const PicturesContainer = (props: PicturesContainerProps) => {
    const [myPictures, setMyPictures] = useState<ReactNode[]>();

    useEffect(() => {
        setPictures(props.pictures);
    }, [props.pictures]);

    const onPictureChange = (file: File, adding: boolean) => {
        const forNothin = myPictures;
        if (adding) {
            if (props.pictures.length > 0)
                props.setPictures([...props.pictures, file]);
            else
                props.setPictures([file]);
        } else {
            const tmpPictures = props.pictures;
            for (let i = 0; i < tmpPictures.length; ++i) {
                if (tmpPictures[i] === file) {
                    tmpPictures.splice(i,1);
                    break;
                }
            }
            if (tmpPictures.length > 0)
                props.setPictures([...tmpPictures]);
            else
                props.setPictures([]);
        }
    };

    const setPictures = (pictures: File[]) => {
        if (pictures.length > 0) {
            const tmpPictures: JSX.Element[] = [];
            for (var i = 0; i < pictures.length; ++i) {
                tmpPictures.push(<Picture className="" pictureTitle={`${translator.decode("buyBack.photo")} ${(i+1).toLocaleString("fr-fr", {minimumIntegerDigits: 2})}`} controlId={`Photo_${i+1}`} inputId={`${translator.decode("buyBack.photo")}_${(i+1).toLocaleString("fr-fr", {minimumIntegerDigits: 2})}`} file={pictures[i]} required={false} onChange={onPictureChange} />);
            }
            if (tmpPictures.length < 10)
                tmpPictures.push(<Picture className="" pictureTitle={`${translator.decode("buyBack.photo")} ${(i+1).toLocaleString("fr-fr", {minimumIntegerDigits: 2})}`} controlId={`Photo_${i+1}`} inputId={`${translator.decode("buyBack.photo")}_${(i+1).toLocaleString("fr-fr", {minimumIntegerDigits: 2})}`} file={undefined} required={false} onChange={onPictureChange} />);
            return setMyPictures(tmpPictures);
        }
        return (
            setMyPictures([<Picture className="" pictureTitle={`${translator.decode("buyBack.photo")} 1`} controlId="Photo_1" inputId="Photo_1" file={undefined} required={false} onChange={onPictureChange} />])
        );
    };

    return (
        <>
            {
                myPictures
            }
        </>
    );
};

export default PicturesContainer;
