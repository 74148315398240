/*
 * File: AutobizService.ts
 * Project: autofi-front
 * File Created: Friday, 17th March 2023 12:20:36
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 17th March 2023 05:38:21
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Axios from "axios";
import Config from "Config/Config";
import ServiceGeneral from "Services/ServiceGeneral";
import util from "util";
import AuthenticationError from "Error/AuthenticationError";
import { AutobizCar } from "Utils/AutobizCar.type";

class AutobizService {
  static async getIntelFromReg(reg: string): Promise<AutobizCar | undefined> {
    try {
      const response = await Axios.get<AutobizCar>(
        Config.BACKEND.BASEURL +
          Config.BACKEND.PATHS.GET_INTEL_FROM_REG +
          "/" +
          reg
      );
      if ((await ServiceGeneral.handleAxiosResponse(response)) === "end") {
        return undefined;
      }
      return response.data;
    } catch (e) {
      if (e instanceof AuthenticationError) throw e;
      const data = ServiceGeneral.interpretError(e);

      return data;
    }
  }

  static async getBrands() {
    try {
      const response = await Axios.get(
        Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_BRANDS
      );
      if ((await ServiceGeneral.handleAxiosResponse(response)) === "end") {
        return undefined;
      }
      return response;
    } catch (e) {
      if (e instanceof AuthenticationError) throw e;
      const data = ServiceGeneral.interpretError(e);

      return data;
    }
  }

  static async getModels(brandId: number) {
    try {
      const response = await Axios.get(
        Config.BACKEND.BASEURL +
          util.format(Config.BACKEND.PATHS.GET_MODELS, brandId)
      );
      if ((await ServiceGeneral.handleAxiosResponse(response)) === "end") {
        return undefined;
      }
      return response;
    } catch (e) {
      if (e instanceof AuthenticationError) throw e;
      const data = ServiceGeneral.interpretError(e);

      return data;
    }
  }

  static async getIntels(brandId: number, modelId: number, year: number) {
    try {
      const response = await Axios.get(
        Config.BACKEND.BASEURL +
          util.format(Config.BACKEND.PATHS.GET_INTELS, brandId, modelId, year)
      );
      if ((await ServiceGeneral.handleAxiosResponse(response)) === "end") {
        return undefined;
      }
      return response;
    } catch (e) {
      if (e instanceof AuthenticationError) throw e;
      const data = ServiceGeneral.interpretError(e);

      return data;
    }
  }
}

export default AutobizService;
